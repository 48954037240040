import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


// element ui
import { Row, Col, Menu, Submenu, MenuItem, MenuItemGroup, Pagination, Select, Option, DatePicker, Message, Progress, Dropdown, DropdownMenu, DropdownItem, } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Row);
Vue.use(Col);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Option);
Vue.use(Progress);
Vue.use(DatePicker);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.prototype.$message = Message;


// axios
import axios from "axios"
Vue.prototype.$axios = axios;


// normalize
import 'normalize.css/normalize.css';
import './style/index.scss';

// VueClipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VueLazyload from 'vue-lazyload';
// or with options
Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'http://benkexx.ixlcg.com/bkxxadmin/images/zhijia.png',
    loading: 'http://benkexx.ixlcg.com/bkxxadmin/images/zhijia.png',
    attempt: 1
});
Vue.use(VueLazyload);

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})



Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')